import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { Layout } from 'containers';
import { Banner } from 'components';
import { BASE_URL } from 'config';

const showdown = require('showdown');

const converter = new showdown.Converter();

const PrivacyPolicyPage = ({ data }) => (
  <Layout page="booking-conditions">
    <Helmet
      title="Privacy Policy"
      link={[{ rel: 'canonical', href: `${BASE_URL}/privacy-policy/` }]}
    />

    <Banner modifier="c-banner--tiny" backgroundImage="https://static-hakatours.imgix.net/footer-bg.jpg?auto=format&fit=crop">
      <h1 className="c-heading c-heading--h1">Haka Tours Privacy Policy</h1>
    </Banner>
    <section className="l-section">
      <div className="l-container l-container--small">
        <div
          dangerouslySetInnerHTML={{
            __html: converter.makeHtml(data.allStaticPages.edges[0].node.content_markdown),
          }}
        />
      </div>
    </section>
  </Layout>
);

PrivacyPolicyPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PrivacyPolicyPage;

export const query = graphql`
  query {
    allStaticPages(
      filter: {
        slug: { eq: "privacy-policy" },
      }
    ) {
      edges {
        node {
          id
          api_id
          slug
          content_markdown
        }
      }
    }
  }
`;
